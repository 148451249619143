import {
  PaymentInitiationResponse,
  ConfirmPaymentResponse,
  SingleImmediatePaymentBody,
  PaymentStateResponse,
  StandingOrderPaymentBody,
  ConfirmStandingOrderResponse,
  RTPUpdateResponse,
  CardPaymentPayload,
} from "@APP/types";
// import CONFIG from "@APP/config";
import request from "../request";

/**
 * Temporary base route to enable proxy for API requests.
 * Addresses https://bankifi.atlassian.net/browse/BK-46.
 */
const TEMP_API_BASE = "/api";

// Bank Payments

export const makePayment = async (
  bank_id: string,
  body: SingleImmediatePaymentBody,
  redirectUrl: string,
  invoiceUrl?: string,
) => {
  const res = await request<PaymentInitiationResponse>({
    method: "POST",
    url: `${TEMP_API_BASE}/banks/${bank_id}/payments`,
    data: body,
    params: {
      redirectUrl,
      invoiceUrl,
    },
  });
  return res.data;
};

export const authorizePayment = async (
  bank_id: string,
  consent_id: string,
  authorizationCode: string,
  redirectUrl: string,
  body: { additionalData: { initialInvoiceType: string } },
) => {
  const res = await request<ConfirmPaymentResponse>({
    method: "POST",
    // url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/${bank_id}/payments/${consent_id}/confirm?authorizationCode=${authorizationCode}&redirectUrl=${redirectUrl}`,
    url: `${TEMP_API_BASE}/banks/${bank_id}/payments/${consent_id}/confirm?authorizationCode=${authorizationCode}&redirectUrl=${redirectUrl}`,
    data: body,
    timeout: 300000,
  });
  return res.data;
};

export const getPaymentState = async (state: string) => {
  const res = await request<PaymentStateResponse>({
    method: "GET",
    // url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/consent/state/${state}`,
    url: `${TEMP_API_BASE}/consent/state/${state}`,
  });
  return res.data;
};

export const createStandingOrderPayment = async (
  bank_id: string,
  body: StandingOrderPaymentBody,
  redirectUrl: string,
  invoiceUrl?: string,
) => {
  const res = await request<PaymentInitiationResponse>({
    method: "POST",
    url: `${TEMP_API_BASE}/banks/${bank_id}/payments/standing-orders`,
    data: body,
    params: {
      redirectUrl,
      invoiceUrl,
    },
  });
  return res.data;
};

export const confirmStandingOrderPayment = async (
  bank_id: string,
  consent_id: string,
  authorizationCode: string,
  redirectUrl: string,
  body: { additionalData: { initialInvoiceType: string } },
) => {
  const res = await request<ConfirmStandingOrderResponse>({
    method: "POST",
    // url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/${bank_id}/payments/${consent_id}/confirm?authorizationCode=${authorizationCode}&redirectUrl=${redirectUrl}`,
    url: `${TEMP_API_BASE}/banks/${bank_id}/payments/standing-orders/${consent_id}/confirm?authorizationCode=${authorizationCode}&redirectUrl=${redirectUrl}`,
    data: body,
    timeout: 300000,
  });
  return res.data;
};

export const updateStandingOrderPayment = async (
  rtpId: string,
  standingOrderId: string,
  firstPaymentDateTime: string,
) => {
  const res = await request<RTPUpdateResponse>({
    method: "POST",
    // url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/${bank_id}/payments/${consent_id}/confirm?authorizationCode=${authorizationCode}&redirectUrl=${redirectUrl}`,
    url: `${TEMP_API_BASE}/admin/request-to-pay/payment-requests/${rtpId}/update-standing-order`,
    data: {
      id: standingOrderId,
      firstPaymentDateTime,
    },
  });
  return res.data;
};

// Card Payments - Square

export const getConsentId = async (customerId: string, orgId: string) => {
  const res = await request<any>({
    method: "POST",
    url: `${TEMP_API_BASE}/cards/square/payments`,
    data: {
      customerId: customerId,
      orgId: orgId,
    },
  });
  return res.data;
};

export const confirmCardPayment = async (
  consentId: string,
  token: string,
  payload: CardPaymentPayload,
  verificationToken?: string,
) => {
  const res = await request<any>({
    method: "POST",
    url: `${TEMP_API_BASE}/cards/square/payments/${consentId}/confirm`,
    data: { ...payload, paymentToken: token, verificationToken: verificationToken },
  });
  return res;
};
