import { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";

import CONFIG from "@APP/config";
import { API } from "@APP/services";
import { useAppSelector } from "@APP/redux";
import { history, SCREEN_PATHS } from "@APP/navigation";
import {
  PaymentInitiationResponse,
  PaymentType,
  SchemeName,
  SingleImmediatePaymentBody,
  StandingOrderPaymentBody,
  ATTACHEMENTS,
} from "@APP/types";
import { Message, MoneyhubLegalInfo, PageLayout, CardPaymentButton } from "@APP/components";
import { configureLogUser, formatCurrency, formatDateToUTCWithEndDay, logData } from "@APP/utils";

const InitiateRTPPaymentScreen = () => {
  const rtpState = useAppSelector((state) => state.rtp);
  const bankState = useAppSelector((state) => state.banks);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const bankId = bankState.selectedBank;
  const invoiceUrl = rtpState.rtpData?.payable?.attachments.find(
    (item) => item.name === ATTACHEMENTS.RECEIVABLE_PDF && item.contentType === "application/pdf",
  )?.uri;

  const bank = bankState.banks?.find((item) => item.id === bankId);
  const isMetro = bank?.provider === "metro";
  const bankName = bankState.banks?.length
    ? bankState.banks.find((bank) => bank.id === bankId)?.fullName
    : bankId;

  useEffect(() => {
    if (!bankName) {
      handleReturn();
    }
  }, [bankName]);

  const getAccountNo = (rtpAccNo: string): string => {
    const letterCheck = /[a-zA-Z]/g;
    if (rtpAccNo.includes("-")) return rtpAccNo.split("-").join("");
    if (!rtpAccNo || rtpAccNo.length === 0) return "00000000000000";
    if (letterCheck.test(rtpAccNo)) return rtpAccNo.slice(-14);
    return rtpAccNo;
  };

  const getFormatSingleImmediatePaymentBody = (): SingleImmediatePaymentBody => ({
    amount: {
      amount: rtpState.rtpData?.paymentDetails.amount.amount ?? "0",
      currency: rtpState.rtpData?.paymentDetails.amount.currency ?? "GBP",
    },
    creditAccount: {
      schemeName: rtpState.rtpData?.paymentDetails.creditAccount.schemeName as SchemeName,
      identification: getAccountNo(
        rtpState.rtpData?.paymentDetails.creditAccount.identification ?? "",
      ),
      name: rtpState.senderData?.businessContact?.name ?? rtpState.senderData?.name,
    },
    paymentType: PaymentType.Immediate,
    additionalData: {
      BankId: bankId ?? "",
      requestId: rtpState.rtpData?.paymentRequestId ?? "",
      erpId: rtpState.rtpData?.payable.entityDetails.source ?? "",
      invoiceId: rtpState.rtpData?.payable.entityDetails.externalId ?? "",
      invoiceType: rtpState.rtpData?.payable.invoiceType ?? "",
      customerId: rtpState.rtpData?.payable.supplierContact.email ?? "",
      orgId: rtpState.senderData?.companyInfo?.id ?? "",
      initialInvoiceType: rtpState.rtpData!.paymentDetails.additionalData.initialInvoiceType ?? "",
      // Including `features` stringified in order to identify native invoice:
      features: rtpState.rtp?.receivable.features?.join() ?? "",
    },
    remittanceInformation: {
      reference: rtpState.rtpData?.payable.reference,
    },
  });

  const getStandingOrderPaymentBody = (): StandingOrderPaymentBody => {
    return {
      bankId: bankId ?? "",
      frequency: rtpState.rtp!.standingOrder!.frequency,
      firstPaymentAmount: rtpState.rtp!.standingOrder!.firstPaymentAmount,
      firstPaymentDateTime: formatDateToUTCWithEndDay(
        new Date(rtpState.rtp!.standingOrder!.firstPaymentDate!),
      ),
      numberOfPayments: String(rtpState.rtp!.standingOrder!.numberOfPayments!),
      creditAccount: {
        schemeName: rtpState.rtpData?.paymentDetails.creditAccount.schemeName as SchemeName,
        identification: getAccountNo(
          rtpState.rtpData?.paymentDetails.creditAccount.identification ?? "",
        ),
        name: rtpState.senderData?.businessContact?.name ?? rtpState.senderData?.name,
      },
    };
  };

  const handleReturn = () => history.replace(SCREEN_PATHS.BANK_SELECT);

  const handlePaymentInitiation = async () => {
    if (isMetro) {
      return history.push(SCREEN_PATHS.METRO_PAYMENT);
    }

    setLoading(true);
    try {
      let paymentData: PaymentInitiationResponse | undefined;

      await configureLogUser(rtpState);
      await logData("Redirection to MH", rtpState.rtpData?.paymentRequestId, 0, {
        contexts: { bankName: bankName },
      });

      if (rtpState.rtp?.standingOrder) {
        const paymentBody = getStandingOrderPaymentBody();
        paymentData = await API.createStandingOrderPayment(
          bankId!,
          paymentBody,
          window.location.origin + CONFIG.API.REDIRECT_PATH_PAYMENT_COMPLETE,
          invoiceUrl,
        );
      } else {
        const paymentBody = getFormatSingleImmediatePaymentBody();
        paymentData = await API.makePayment(
          bankId!,
          paymentBody,
          window.location.origin + CONFIG.API.REDIRECT_PATH_PAYMENT_COMPLETE,
          invoiceUrl,
        );
      }

      await API.initiateRTP(rtpState.rtpData?.paymentRequestId!);
      window.location.href = paymentData?.redirectUrl;
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const renderMainContent = () => {
    if (!bankId) {
      return (
        <Box display="flex" alignItems="center" pt="12vh" id="initiateRtpPaymentScreenNoBank">
          <Message
            type="error"
            title="Oops, something seems to have gone wrong"
            description="We were unable to find the bank you selected. Please try again."
            buttons={[{ variant: "contained", color: "secondary", onClick: handleReturn }]}
          />
        </Box>
      );
    }

    if (error) {
      return (
        <Box display="flex" alignItems="center" pt="12vh" id="initiateRtpPaymentScreenError">
          <Message
            type="error"
            title="Oops, something seems to have gone wrong"
            description="Sorry, we have been unable to reach your chosen bank, please try again or select a different bank to make payment from"
          />
        </Box>
      );
    }

    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        id="initiateRtpPaymentScreenBox">
        <Box my={3}>
          <Typography
            align="center"
            component="p"
            variant="h6"
            id="initiateRtpPaymentScreenInitiateTypo">
            Initiate a payment with {bankName}
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography align="center" id="initiateRtpPaymentScreenClicktoPay">
            Click Pay to initiate a payment of{" "}
            {formatCurrency(rtpState.rtpData?.paymentDetails.amount)} to{" "}
            {rtpState.senderData?.businessContact?.name ?? rtpState.senderData?.name} from your
            account at <b style={{ textTransform: "capitalize" }}>{bankName}</b>.
          </Typography>
        </Box>
        <Box width="100%" mb={2}>
          <Button
            size="large"
            fullWidth
            disabled={loading}
            variant="contained"
            id="initiateRtpPaymentScreenDoPaymentButton"
            color="primary"
            onClick={handlePaymentInitiation}>
            {loading ? <CircularProgress color="secondary" size={31} /> : `Pay with ${bankName}`}
          </Button>
        </Box>
        <Box width="100%" mb={2}>
          <Typography align="center" id="initiateRtpPaymentScreenOrTypo">
            or
          </Typography>
        </Box>
        <Box width="100%" mb={2}>
          <Button
            size="large"
            fullWidth
            variant="contained"
            color="secondary"
            id="initiateRtpPaymentScreenChooseAnotherBankButton"
            onClick={handleReturn}>
            Choose another bank
          </Button>
        </Box>
        {CONFIG?.FEATURE?.SQUARE && rtpState.rtp?.cardPaymentProvider ? (
          <CardPaymentButton />
        ) : null}
        {!isMetro ? <MoneyhubLegalInfo /> : null}
      </Box>
    );
  };

  return (
    <PageLayout loading={loading}>
      <Box display="flex" alignItems="center" flexDirection="column" maxWidth={530} mx="auto">
        {renderMainContent()}
      </Box>
    </PageLayout>
  );
};

export default InitiateRTPPaymentScreen;
