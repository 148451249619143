// Dependencies
import { createStitches } from "@stitches/react";

export const { keyframes, styled } = createStitches();

export const ApplePayContainer = styled("div", {
  ApplePayButtonStyle: "black",
  ApplePayButtonType: "plain",
  cursor: "pointer",
  display: "inline-block",
  height: 48,
  WebkitAppearance: "-apple-pay-button",
  width: "100%",
});
