// import store from "@APP/redux";
import axios, { AxiosRequestConfig } from "axios";
import logService from "./logService";

// import { HEADERS } from "./constants";

type RequestOptions = {
  /**
   * Attaches auth token header to the request.
   */
  auth?: boolean;
  /**
   * Logs the request error remotely.
   */
  logError?: boolean;
};

const REQUEST_DEFAULT_OPTIONS: RequestOptions = {
  auth: true,
  logError: true,
};

// Singleton flag to ensure interceptors are not registered multiple times
let interceptorsRegistered = false;

const setupInterceptors = () => {
  if (interceptorsRegistered) return;
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      try {
        // Respect the `sendLogsToBE` option from the axios config and send logs non-blocking
        // const orgId = jwtDecode<Organisation>(token as string).org_id;
        const logzEnabled = window?.env?.REACT_APP_ENABLE_LOGZ_LOGGING === "true" ? true : false;
        logService.logError(error, null, logzEnabled); // Non-blocking, no await
      } catch (error) {}

      return Promise.reject(error);
    },
  );

  interceptorsRegistered = true;
};

// Initialize interceptors once when the module is loaded
setupInterceptors();

/**
 * HTTP client wrapper around "axios" API.
 * @param config "axios" config: https://github.com/axios/axios#request-config
 * @param options
 *  "auth" - include auth header in the request;
 */
const request = async <T = any>(config: AxiosRequestConfig, options = REQUEST_DEFAULT_OPTIONS) => {
  options = { ...REQUEST_DEFAULT_OPTIONS, ...options };
  let headers = config.headers || {};

  return axios.request<T>({ ...config, headers });
};

export default request;
