type ErrorMessage = { title: string; text: string };

const ERROR_CODES_LIST = [
  { code: 1001, message: "invalid_request" },
  { code: 1002, message: "unauthorized_client" },
  { code: 1003, message: "unsupported_response_type" },
  { code: 1004, message: "invalid_scope" },
  { code: 1005, message: "server_error" },
  { code: 1006, message: "temporarily_unavailable" },
  { code: 1007, message: "invalid_request_uri" },
  { code: 1008, message: "invalid_request_object" },
  { code: 1009, message: "access_denied" },
  { code: 1010, message: "interaction_required" },
  { code: 1011, message: "login_required" },
  { code: 1012, message: "account_selection_required" },
  { code: 1013, message: "consent_required" },
  { code: 1016, message: "request_not_supported" },
  { code: 1017, message: "request_uri_not_supported" },
  { code: 1018, message: "registration_not_supported" },
] as const;

export type ErrorCode = (typeof ERROR_CODES_LIST)[number];

const ERROR_MESSAGES: { errors: ErrorCode[]; message: ErrorMessage }[] = [
  {
    errors: [
      { code: 1001, message: "invalid_request" },
      { code: 1002, message: "unauthorized_client" },
      { code: 1003, message: "unsupported_response_type" },
      { code: 1004, message: "invalid_scope" },
      { code: 1005, message: "server_error" },
      { code: 1006, message: "temporarily_unavailable" },
      { code: 1007, message: "invalid_request_uri" },
      { code: 1008, message: "invalid_request_object" },
      { code: 1016, message: "request_not_supported" },
      { code: 1017, message: "request_uri_not_supported" },
      { code: 1018, message: "registration_not_supported" },
    ],
    message: {
      title: "Your payment was not completed successfully",
      text: "Your bank did not complete your payment successfully. Please try again in a few moments.",
    },
  },
  {
    errors: [{ code: 1009, message: "access_denied" }],
    message: {
      title: "You have cancelled the payment at your bank",
      text: "If there is a problem with the payment request please contact us to discuss.",
    },
  },
  {
    errors: [
      { code: 1010, message: "interaction_required" },
      { code: 1011, message: "login_required" },
      { code: 1012, message: "account_selection_required" },
      { code: 1013, message: "consent_required" },
    ],
    message: {
      title: "It looks like you did not complete the payment process at your bank.",
      text: "Your payment was not authorised by your bank. Please check your paying bank account.",
    },
  },
];

export function findErrorCodeByMessage(message: string): {
  errorFound: boolean;
  errorCode: string | null;
  errorMessage: ErrorMessage | null;
} {
  for (const { errors, message: errorMessage } of ERROR_MESSAGES) {
    const error = errors.find((error) => error.message === message);
    if (error) {
      return {
        errorFound: true,
        errorCode: error.code ? `<br><small>Code - ${error.code}</small>` : "",
        errorMessage,
      };
    }
  }
  return { errorFound: false, errorCode: null, errorMessage: null };
}
