import CONFIG from "@APP/config";
import * as Sentry from "@sentry/react";

// Function to log data
export async function configureLogUser(rtpState?: any) {
  if (CONFIG.SERVICES.SENTRY && !["test", "development"].includes(process.env.NODE_ENV)) {
    Sentry.setUser({ id: rtpState.rtpData?.paymentRequestId });
    Sentry.setTag("org_id", rtpState?.senderData?.companyInfo?.id);
    Sentry.setTag("rtp_id", rtpState.rtpData?.paymentRequestId);
  }
}

// Function to log data
export async function logData(message: string, rtpId?: string, type: number = 0, data?: any) {
  if (CONFIG.SERVICES.SENTRY && !["test", "development"].includes(process.env.NODE_ENV)) {
    if (type === 0) {
      Sentry.captureMessage(message + " - " + rtpId, data);
    } else {
      Sentry.captureException(message);
    }
  }
}
