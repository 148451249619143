import CONFIG from "@APP/config";
import { BanksResponse, MetroBankAccountDetails } from "@APP/types";

import request from "../request";

/**
 * Temporary base route to enable proxy for API requests.
 * Addresses https://bankifi.atlassian.net/browse/BK-46.
 */
const TEMP_API_BASE = "/api";

export const getBanks = async () => {
  const res = await request<{ data: BanksResponse[] }>({
    method: "GET",
    // url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/custodians?${CONFIG.API.CUSTODIANS_PROVIDER_PATH}`,
    url: `${TEMP_API_BASE}/custodians?${CONFIG.API.CUSTODIANS_PROVIDER_PATH}`,
  });
  return res.data.data;
};

export const getMetroRedirectUrl = async (
  bankId: string,
  additionalData?: { orgId?: string; requestId?: string },
) => {
  const res = await request<{ redirectUrl: string }>({
    method: "POST",
    // url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/${bankId}/payments`,
    url: `${TEMP_API_BASE}/banks/${bankId}/payments`,
    data: {
      additionalData,
    },
  });
  return res.data.redirectUrl;
};

export const getMetroBankAccountDetails = async (email: string) => {
  const res = await request<MetroBankAccountDetails>({
    method: "GET",
    // url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/metro/accounts/${email}`,
    url: `${TEMP_API_BASE}/banks/metro/accounts/${email}`,
  });
  return res.data;
};

export const storeConsentError = async (
  custodianId: string,
  consentId: string,
  error: string,
  error_description: string,
) => {
  const res = await request<MetroBankAccountDetails>({
    method: "PUT",
    // url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/banks/metro/accounts/${email}`,
    url: `${TEMP_API_BASE}/banks/${custodianId}/consent/${consentId}/revoke`,
    data: {
      error,
      description: {
        errorDescription: error_description,
      },
    },
  });
  return res.data;
};
